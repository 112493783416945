import React from 'react';
import { connect } from 'react-redux';
import MessageForm from './MessageForm';
import { updateApprovalFormInfo } from '../../../../../../store/approvalFormInfoReducer';

const MessageFormWrapper = ({ onFormInit, updateApprovalFormInfo, approvalFormInfo, user, approvalFormId }) => {
  const onSubmit = (data) => {
    updateApprovalFormInfo(data, user.userId, approvalFormId);
  };

  return (
    <MessageForm
      onSubmit={onSubmit}
      onFormInit={onFormInit}
      approvalFormInfo={approvalFormInfo}
      requestor={user.email}
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.auth,
});

export default connect(mapStateToProps, { updateApprovalFormInfo })(MessageFormWrapper);
