import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { deleteApprovalGroup, getApprovalGroups } from '../../../../store/approvalFormsReducer';
import ApprovalGroupsTable from './ApprovalGroupsTable';
import { LOCALE } from '../../../../locale';

const ApprovalGroups = ({ approvalGroups, getApprovalGroups, adminId }) => {
  const locale = LOCALE.common.dashboard.approvalGroups;

  const fetchApprovalGroups = async () => {
    await getApprovalGroups();
  };
  useEffect(() => {
    fetchApprovalGroups();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box m="0 0 2rem">
        <Typography variant="h5" component="h1" align="center">
          {locale.headline}
        </Typography>
      </Box>
      <ApprovalGroupsTable approvalGroups={approvalGroups} adminId={adminId} />
    </>
  );
};

const mapStateToProps = (state) => ({
  approvalGroups: state.approvalForm.approvalGroups,
  adminId: state.auth.userId,
});

export default connect(mapStateToProps, { getApprovalGroups, deleteApprovalGroup })(ApprovalGroups);
