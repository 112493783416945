import { createMuiTheme } from '@material-ui/core/styles';

const fontFamily = '"Roboto", "Helvetica", "Arial", sans-serif';

export const theme = createMuiTheme({
  'palette': {
    'common': {
      'black': '#000',
      'white': '#fff',
    },
    'primary': {
      'light': '#7986cb',
      'main': '#3f51b5',
      'dark': '#303f9f',
      'contrastText': '#fff',
    },
    'secondary': {
      'light': '#ff4081',
      'main': '#f50057',
      'dark': '#c51162',
      'contrastText': '#fff',
    },
    'error': {
      'light': '#e57373',
      'main': '#f44336',
      'dark': '#d32f2f',
      'contrastText': '#fff',
    },
    'warning': {
      'light': '#ffb74d',
      'main': '#ff9800',
      'dark': '#f57c00',
      'contrastText': 'rgba(0, 0, 0, 0.87)',
    },
    'info': {
      'light': '#64b5f6',
      'main': '#2196f3',
      'dark': '#1976d2',
      'contrastText': '#fff',
    },
    'success': {
      'light': '#81c784',
      'main': '#4caf50',
      'dark': '#388e3c',
      'contrastText': 'rgba(0, 0, 0, 0.87)',
    },
    'grey': {
      '50': '#fafafa',
      '100': '#f5f5f5',
      '200': '#eeeeee',
      '300': '#e0e0e0',
      '400': '#bdbdbd',
      '500': '#9e9e9e',
      '600': '#757575',
      '700': '#616161',
      '800': '#424242',
      '900': '#212121',
      'A100': '#d5d5d5',
      'A200': '#aaaaaa',
      'A400': '#303030',
      'A700': '#616161',
    },
    'text': {
      'primary': 'rgba(0, 0, 0, 0.87)',
      'secondary': 'rgba(0, 0, 0, 0.54)',
      'disabled': 'rgba(0, 0, 0, 0.38)',
      'hint': 'rgba(0, 0, 0, 0.38)',
    },
    'divider': 'rgba(0, 0, 0, 0.12)',
    'background': {
      'paper': '#fff',
      'default': '#fafafa',
    },
    'action': {
      'active': 'rgba(0, 0, 0, 0.54)',
      'hover': 'rgba(0, 0, 0, 0.04)',
      'hoverOpacity': 0.04,
      'selected': 'rgba(0, 0, 0, 0.08)',
      'selectedOpacity': 0.08,
      'disabled': 'rgba(0, 0, 0, 0.26)',
      'disabledBackground': 'rgba(0, 0, 0, 0.12)',
      'disabledOpacity': 0.38,
      'focus': 'rgba(0, 0, 0, 0.12)',
      'focusOpacity': 0.12,
      'activatedOpacity': 0.12,
    },
  },
  'typography': {
    'htmlFontSize': 16,
    'fontFamily': fontFamily,
    'fontSize': 14,
    'fontWeightLight': 300,
    'fontWeightRegular': 400,
    'fontWeightMedium': 500,
    'fontWeightBold': 700,
    'h1': {
      'fontFamily': fontFamily,
      'fontWeight': 300,
      'fontSize': '6rem',
      'lineHeight': 1.167,
      'letterSpacing': '-0.01562em',
    },
    'h2': {
      'fontFamily': fontFamily,
      'fontWeight': 300,
      'fontSize': '3.75rem',
      'lineHeight': 1.2,
      'letterSpacing': '-0.00833em',
    },
    'h3': {
      'fontFamily': fontFamily,
      'fontWeight': 400,
      'fontSize': '3rem',
      'lineHeight': 1.167,
      'letterSpacing': '0em',
    },
    'h4': {
      'fontFamily': fontFamily,
      'fontWeight': 400,
      'fontSize': '2.125rem',
      'lineHeight': 1.235,
      'letterSpacing': '0.00735em',
    },
    'h5': {
      'fontFamily': fontFamily,
      'fontWeight': 400,
      'fontSize': '1.5rem',
      'lineHeight': 1.334,
      'letterSpacing': '0em',
    },
    'h6': {
      'fontFamily': fontFamily,
      'fontWeight': 500,
      'fontSize': '1.25rem',
      'lineHeight': 1.6,
      'letterSpacing': '0.0075em',
    },
    'subtitle1': {
      'fontFamily': fontFamily,
      'fontWeight': 400,
      'fontSize': '1rem',
      'lineHeight': 1.75,
      'letterSpacing': '0.00938em',
    },
    'subtitle2': {
      'fontFamily': fontFamily,
      'fontWeight': 500,
      'fontSize': '0.875rem',
      'lineHeight': 1.57,
      'letterSpacing': '0.00714em',
    },
    'body1': {
      'fontFamily': fontFamily,
      'fontWeight': 400,
      'fontSize': '1rem',
      'lineHeight': 1.5,
      'letterSpacing': '0.00938em',
    },
    'body2': {
      'fontFamily': fontFamily,
      'fontWeight': 400,
      'fontSize': '0.875rem',
      'lineHeight': 1.43,
      'letterSpacing': '0.01071em',
    },
    'button': {
      'fontFamily': fontFamily,
      'fontWeight': 500,
      'fontSize': '0.875rem',
      'lineHeight': 1.75,
      'letterSpacing': '0.02857em',
      'textTransform': 'uppercase',
    },
    'caption': {
      'fontFamily': fontFamily,
      'fontWeight': 400,
      'fontSize': '0.75rem',
      'lineHeight': 1.66,
      'letterSpacing': '0.03333em',
    },
    'overline': {
      'fontFamily': fontFamily,
      'fontWeight': 400,
      'fontSize': '0.75rem',
      'lineHeight': 2.66,
      'letterSpacing': '0.08333em',
      'textTransform': 'uppercase',
    },
  },
  'shape': {
    'borderRadius': 4,
  },
});
