import React from 'react';
import Moment from 'react-moment';
import HTMLReactParser from 'html-react-parser';
import { Grid, Box, Typography, Divider } from '@material-ui/core';
import { DATE_TIME_FORMAT } from '../../../../../../utils/validators';
import { setLocalDate } from '../../../../../../utils/helpers/date-helpers';

const Message = ({ message }) => {
  const { username, createdAt, comment } = message;
  const newDate = setLocalDate(createdAt);

  return (
    <>
      <Box p="1rem 0">
        <Grid container alignItems="center">
          <Typography variant="subtitle1" color="primary">
            {username}
          </Typography>
        </Grid>

        {message
        && (
          <>
            <Typography
              variant="body2"
              component="div"
              gutterBottom
            >
              {HTMLReactParser(comment)}
            </Typography>
          </>
        )}

        <Typography variant="caption" color="textSecondary">
          <Moment format={DATE_TIME_FORMAT}>{newDate}</Moment>
        </Typography>
      </Box>
      <Divider />
    </>
  );
};

export default Message;
