import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Box, Button, Card, CardContent, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { renderTextField } from '../../../shared/common/FormControls/FormControls';
import themeStyles from './ProfileForm.styles';
import { LOCALE } from '../../../../locale';

const useStyles = makeStyles((theme) => themeStyles(theme));

const CreateApprovalGroup = ({ handleSubmit }) => {
  const classes = useStyles();
  const locale = LOCALE.common.dashboard.approvalGroups;

  return (
    <Container maxWidth="md" disableGutters>
      <Card className={classes.root}>
        <CardContent>
          <Box m="0 0 0.5rem" display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h5" component="h1" align="center" gutterBottom>
              {locale.headline}
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Field
              name="groupName"
              label={locale.approvalGroupName}
              variant="outlined"
              margin="normal"
              fullWidth
              component={renderTextField}
            />
            <Field
              name="emails"
              label={locale.memberEmails}
              variant="outlined"
              margin="normal"
              fullWidth
              component={renderTextField}
            />
            <Box m="1rem 0 0">
              <Button type="submit" fullWidth variant="contained" color="primary">
                Create
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default reduxForm({
  form: 'approval-group',
})(CreateApprovalGroup);
