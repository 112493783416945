import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { reduxForm, Field, change } from 'redux-form';
import _ from 'lodash';
import { DialogContent, Typography, Grid, Button, Box, MenuItem } from '@material-ui/core';
import moment from 'moment';
import RichTextarea from '../../../../../shared/dashboard/RichTextarea/RichTextarea';
import { LOCALE } from '../../../../../../locale';
import { renderSelectField } from '../../../../../shared/common/FormControls/FormControls';
import './MessageForm.scss';
import themeStyles from '../ApprovalFormInfo.styles';
import { DATE_FORMAT } from '../../../../../../utils/validators';

const bodyField = ({ input, meta: { touched, error } }) => (
  <div className={touched && error ? 'bodyFieldError' : ''}>
    <div className="popupBodyFieldErrorMsg">{touched && error && <span>{error}</span>}</div>
    <input {...input} type="hidden" />
  </div>
);

const actionList = [
  ['DECLINED', 'REJECT'],
  ['ACCEPTED', 'ACCEPT'],
];

const RenderTable = ({ memberResponses }) => {
  console.log('memberResponses', memberResponses); // eslint-disable-line
  const locale = LOCALE.admin.dashboard.approvalFormInfo;
  const useStyles = () => createMuiTheme(themeStyles);
  const columns = [
    {
      name: 'username',
      label: locale.tableHeaders.username,
    },
    {
      name: 'response',
      label: locale.tableHeaders.response,
    },
    {
      name: 'updatedDate',
      label: locale.tableHeaders.updatedDate,
      options: {
        sort: true,
        searchable: false,
        customBodyRender: (timestamp) => moment(timestamp).format(DATE_FORMAT),
      },
    },
  ];
  const tableData = memberResponses
    && _.cloneDeep(memberResponses).map((item) => {
      item.username = item.name;
      item.response = item.formStatus;
      item.updatedDate = item.recordedOn;
      return item;
    });

  const options = {
    download: false,
    print: false,
    selectableRows: false,
    searchOpen: false,
    search: false,
    filter: false,
    sort: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15, 20],
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: locale.noData,
      },
    },
  };
  return (
    <MuiThemeProvider theme={useStyles()}>
      <MUIDataTable data={tableData || []} columns={columns} options={options} />
    </MuiThemeProvider>
  );
};
const MessageForm = ({ onFormInit, handleSubmit, dispatch, approvalFormInfo }) => {
  const editorHeight = 200;
  const locale = LOCALE.admin.dashboard.approvalFormInfo;
  const [response, setResponse] = useState(null);

  const responseChange = (event) => {
    setResponse(event.target.value);
  };
  const onEditorChange = (data) => {
    dispatch(change('approvalform-info-message', 'comment', data.length ? data : null));
  };
  const handleTextareaInit = (status) => {
    onFormInit(status);
  };

  return (
    <Box m="0 0 1.5rem">
      <Typography variant="subtitle1" color="primary">
        {locale.comments}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box m="1rem 1rem">
          <Field name="message" type="hidden" component={bodyField} />
          <Box className="messageBodyEditor">
            <RichTextarea height={editorHeight} onChange={onEditorChange} onInit={handleTextareaInit} />
          </Box>
          {approvalFormInfo.formCreatorResponse && (
          <Button color="primary" type="submit" variant="contained" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            {locale.form.submitButton}
          </Button>
          )}
        </Box>
        <Grid container justify="space-between">
          {approvalFormInfo.formCreatorResponse ? (
            <>
              <DialogContent dividers style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <Typography variant="subtitle1" color="primary">
                  Member Responses
                </Typography>
                <RenderTable memberResponses={approvalFormInfo.memberResponses} />
              </DialogContent>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      select
                      name="response"
                      label={locale.form.response}
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={response}
                      onChange={responseChange}
                      component={renderSelectField}
                    >
                      {actionList.map((option) => (
                        <MenuItem key={option[0]} value={option[0]}>
                          {option[1]}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={6} />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container justify="flex-end" className="submitWrap">
                  <Button color="primary" type="submit" variant="contained">
                    {locale.form.submitButton}
                  </Button>
                </Grid>
              </Grid>

            </>
          )}
        </Grid>
      </form>
    </Box>
  );
};

export default reduxForm({
  form: 'approvalform-info-message',
})(MessageForm);
