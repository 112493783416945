import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { getAdminApprovalForms } from '../../../../store/approvalFormsReducer';
import ApprovalFormsTable from './ApprovalFormsTable';
import { LOCALE } from '../../../../locale';
import ApprovalFormInfo from './ApprovalFormInfo/ApprovalFormInfo';

const ApprovalForms = ({ approvalForms, getAdminApprovalForms, adminId, approvalForm }) => {
  const locale = LOCALE.common.dashboard.approvalForms;
  const [modalOpen, setModalOpen] = useState(false);
  const [activeApprovalForm, setActiveApprovalForm] = useState(approvalForm);

  useEffect(() => {
    getAdminApprovalForms(adminId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowModal = (approvalForm) => {
    setModalOpen(true);
    setActiveApprovalForm(approvalForm);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  return (
    <>
      <Box m="0 0 2rem">
        <Typography variant="h5" component="h1" align="center">
          {locale.tableHeaders.headline}
        </Typography>
      </Box>
      {approvalForms ? <ApprovalFormsTable onShowModal={handleShowModal} approvalForms={approvalForms} /> : null}
      {activeApprovalForm ? <ApprovalFormInfo open={modalOpen} onClose={handleCloseModal} approvalFormId={activeApprovalForm.formId} /> : null }
    </>
  );
};

const mapStateToProps = (state) => ({
  approvalForms: state.approvalForm.approvalFormsList,
  approvalForm: state.approvalForm.approvalForm,
  adminId: state.auth.userId,
});
export default connect(mapStateToProps, { getAdminApprovalForms })(ApprovalForms);
