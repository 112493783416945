import React, { useState } from 'react';
import _ from 'lodash';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import moment from 'moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { themeStyles } from './TicketsTable.styles';
import { LOCALE } from '../../../../locale';
import { DATE_FORMAT } from '../../../../utils/validators';

const useStyles = () => createMuiTheme(themeStyles);

const ApprovalFormsTable = ({ approvalForms, onShowModal }) => {
  const locale = LOCALE.common.dashboard.approvalForms;
  const [filterDate, setFilterDate] = useState(null);

  const columns = [
    {
      name: 'id',
      label: locale.tableHeaders.formId,
      options: {
        filter: false,
        sort: false,
        searchable: true,
      },
    },
    {
      name: 'status',
      label: locale.tableHeaders.status,
      options: {
        filter: false,
        sort: false,
        searchable: true,
      },
    },
    {
      name: 'subject',
      label: locale.tableHeaders.subject,
      options: {
        filter: false,
        sort: false,
        searchable: true,
      },
    },
    {
      name: 'createdDate',
      label: locale.tableHeaders.date,
      options: {
        sort: true,
        searchable: false,
        customBodyRender: (timestamp) => moment(timestamp).format(DATE_FORMAT),
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: (v) => `${locale.tableHeaders.date} - ${v}`,
          update: (filterList, filterPos, index) => {
            setFilterDate(null);
            filterList[index] = [];
            return filterList;
          },
        },
        filterOptions: {
          fullWidth: true,
          names: [],
          logic: (date, filters) => {
            if (filters.length) {
              return date !== filters[0];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <Box>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  disableToolbar
                  autoOk
                  variant="inline"
                  format="dd/MM/yyyy"
                  placeholder={DATE_FORMAT}
                  margin="normal"
                  fullWidth
                  label={locale.dateFilter}
                  value={filterList[1][0] ? filterDate : null}
                  onChange={(date) => {
                    setFilterDate(date);
                    filterList[index][0] = moment(date, 'DD/MM/YYYY').format(DATE_FORMAT);
                    onChange(filterList[index], index, column);
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>
          ),
        },
      },
    },
  ];

  const tableData = approvalForms
    && _.cloneDeep(approvalForms).map((item) => {
      item.subject = item.subject;
      item.id = item.formId;
      item.status = item.status;
      item.createdDate = item.createdAt;
      return item;
    });

  const options = {
    download: false,
    print: false,
    selectableRows: false,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 25, 50],
    sortOrder: {
      name: 'createdDate',
      direction: 'desc',
    },
    searchPlaceholder: `${locale.tableHeaders.groupName}`,
    textLabels: {
      body: {
        noMatch: locale.noData,
      },
    },
    onRowClick: (rowData) => {
      const ticket = approvalForms.filter((item) => item.formId === rowData[0])[0];
      onShowModal(ticket);
    },
  };

  return (
    <MuiThemeProvider theme={useStyles()}>
      <MUIDataTable data={tableData || []} columns={columns} options={options} />
    </MuiThemeProvider>
  );
};

export default ApprovalFormsTable;
