import _ from 'lodash';
import { reset } from 'redux-form';
import { adminAPI } from '../api';
import { toggleIsDataFetching } from './appReducer';
import { hideNote } from './notificationReducer';
import { serverErrorHelper } from '../utils/helpers/server-error-helper';
import { clearEditorValue } from './richtextReducer';
// Actions
const SET_APPROVAL_FORM_INFO_DATA = 'SET_APPROVAL_FORM_INFO_DATA';
const SET_COMMENTS_DATA = 'SET_COMMENTS_DATA';
const RESET_APPROVAL_FORM_INFO_DATA = 'RESET_APPROVAL_FORM_INFO_DATA';

// Initial Data
const initialState = {
  subject: null,
  memberResponses: [],
  approvalFormCommentResponses: [],
  description: null,
  formCreatorResponse: null,
};

// Reducer
const approvalFormInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_APPROVAL_FORM_INFO_DATA: {
      const { data } = action;
      return {
        ...state,
        subject: _.get(data, 'subject', initialState.subject),
        description: _.get(data, 'description', initialState.description),
        formCreatorResponse: _.get(data, 'formCreatorResponse', initialState.formCreatorResponse),
        approvalFormCommentResponses: _.get(data, 'approvalFormCommentResponses', initialState.approvalFormCommentResponses),
        memberResponses: _.get(data, 'memberResponses', initialState.memberResponses),
      };
    }
    case SET_COMMENTS_DATA: {
      const { data } = action;
      return {
        ...state,
        approvalFormCommentResponses: _.get(data, 'approvalFormCommentResponses', initialState.approvalFormCommentResponses),
      };
    }
    case RESET_APPROVAL_FORM_INFO_DATA: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

// Action Creators
export const setApprovalFormInfo = (data) => ({
  type: SET_APPROVAL_FORM_INFO_DATA,
  data,
});
export const setMessagesData = (data) => ({
  type: SET_COMMENTS_DATA,
  data,
});
export const resetApprovalFormInfo = () => ({ type: RESET_APPROVAL_FORM_INFO_DATA });

// Thunks

export const getApprovalFormInfo = (userId, approvalFormId) => (dispatch) => {
  dispatch(toggleIsDataFetching(true));
  dispatch(hideNote());
  dispatch(resetApprovalFormInfo());
  adminAPI.getApprovalFormInfo(userId, approvalFormId)
    .then((response) => {
      dispatch(toggleIsDataFetching(false));
      dispatch(setApprovalFormInfo(response.data));
    })
    .catch((error) => serverErrorHelper(dispatch, error));
};

export const updateApprovalFormInfo = (data, userId, approvalFormId) => (dispatch) => {
  dispatch(toggleIsDataFetching(true));
  dispatch(hideNote());
  adminAPI.updateApprovalFormInfo(data, userId, approvalFormId)
    .then((response) => {
      dispatch(toggleIsDataFetching(false));
      dispatch(clearEditorValue());
      dispatch(reset('approvalform-info-message'));
      dispatch(setApprovalFormInfo(response.data));
    })
    .catch((error) => serverErrorHelper(dispatch, error));
};

export default approvalFormInfoReducer;
