import { LOCALE } from '../../../../locale';

const validate = (values) => {
  const errors = {};
  const { required, confirmPassword, minChar, reservedClientName } = LOCALE.errors.validation;

  const newPasswordMinLength = 13;

  if (values.newPassword && values.newPassword.length < newPasswordMinLength) {
    errors.newPassword = minChar(newPasswordMinLength);
  }

  if (values.newPassword && values.newPassword.toLowerCase().includes('paraza')) {
    errors.newPassword = reservedClientName;
  }

  if (!values.oldPassword) {
    errors.oldPassword = required;
  }
  if (!values.newPassword) {
    errors.newPassword = required;
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = required;
  } else if (values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = confirmPassword;
  }

  return errors;
};

export default validate;
