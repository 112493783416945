import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Divider, Typography } from '@material-ui/core';
import Message from '../Message/Message';
import SmallPreloader from '../../../../../shared/common/SmallPreloader/SmallPreloader';
import { getApprovalFormInfo } from '../../../../../../store/approvalFormInfoReducer';
import { LOCALE } from '../../../../../../locale';

const Messages = ({ comments, isDataFetching, approvalFormInfo }) => {
  const locale = LOCALE.admin.dashboard.approvalFormInfo;
  const [showLoader, setShowLoader] = useState(comments && !comments.length && isDataFetching);

  useEffect(() => {
    setShowLoader(!comments.length && isDataFetching);
  }, [comments, isDataFetching]);

  if (showLoader) return <SmallPreloader />;

  return (
    <>
      {!!comments.length
      && (
        <>
          {!approvalFormInfo.formCreatorResponse && <Divider /> }
          <Box m="1rem 0 0" minHeight="5rem">
            <Typography variant="subtitle1" color="primary">{locale.conversions}</Typography>
            {comments.sort((a, b) => {
              const dateA = new Date(a.createdAt);
              const dateB = new Date(b.createdAt);
              return dateB - dateA;
            })
              .map((msg) => <Message key={msg.id} message={msg} />)}
          </Box>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  approvalFormInfo: state.approvalFormInfo,
  comments: state.approvalFormInfo.approvalFormCommentResponses,
  isDataFetching: state.app.isDataFetching,
});

export default connect(mapStateToProps, { getApprovalFormInfo })(Messages);
