import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Grid, Box, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import HTMLReactParser from 'html-react-parser';
import SmallPreloader from '../../../../shared/common/SmallPreloader/SmallPreloader';
import themeStyles from './ApprovalFormInfo.styles';
import MessageFormWrapper from './MessageForm/MessageFormWrapper';
import Messages from './Messages/Messages';
import { getApprovalFormInfo, resetApprovalFormInfo } from '../../../../../store/approvalFormInfoReducer';

const useStyles = makeStyles((theme) => themeStyles(theme));

const ApprovalFormInfo = ({
  getApprovalFormInfo,
  resetApprovalFormInfo,
  open,
  onClose,
  user,
  approvalFormInfo,
  search,
  approvalFormId,
}) => {
  const classes = useStyles();
  const [msgFormReady, setMsgFormReady] = useState(false);

  const handleClose = () => {
    resetApprovalFormInfo();
    onClose();
  };
  const handleFormInit = (status) => {
    setMsgFormReady(status);
  };

  useEffect(() => {
    if (open && !search) getApprovalFormInfo(user.userId, approvalFormId);
  }, [getApprovalFormInfo, approvalFormId, user, open, search]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      classes={{
        scrollPaper: classes.scrollPaper,
        paper: classes.paper,
      }}
    >
      {!msgFormReady && <SmallPreloader />}
      <DialogTitle>
        <Grid container justify="space-between" alignItems="flex-start">
          <Box className={classes.subjectWrap}>
            <Typography variant="subtitle1" color="primary">
              Subject
            </Typography>
            <Typography variant="h6" className={classes.subject}>
              {approvalFormInfo.subject}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" color="primary">
              Approval Form Id
            </Typography>
            <Typography variant="h6" className={classes.subject}>
              {approvalFormId}
            </Typography>
          </Box>
          <IconButton aria-label="close" size="small" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Box className={classes.subjectWrap}>
          <Typography variant="subtitle1" color="primary">
            Description
          </Typography>
          {approvalFormInfo.description && (
            <Typography variant="body1">
              {HTMLReactParser(approvalFormInfo.description)}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogContent dividers>
        {!msgFormReady && <SmallPreloader />}
        <Box style={{ visibility: msgFormReady ? 'visible' : 'hidden' }}>
          <MessageFormWrapper onFormInit={handleFormInit} approvalFormInfo={approvalFormInfo} approvalFormId={approvalFormId} />
        </Box>
        { approvalFormInfo && <Messages /> }
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  approvalFormInfo: state.approvalFormInfo,
  user: state.auth,
});

export default connect(mapStateToProps, {
  getApprovalFormInfo,
  resetApprovalFormInfo,
})(ApprovalFormInfo);
