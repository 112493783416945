import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import CreateApprovalForm from './CreateApprovalForm';
import { createApprovalForm, getApprovalGroups } from '../../../../store/approvalFormsReducer';

const NewApprovalFormContainer = ({ user, getApprovalGroups, createApprovalForm, approvalGroups }) => {
  const fetchApprovalGroups = async () => {
    await getApprovalGroups();
  };
  useEffect(() => {
    fetchApprovalGroups();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    createApprovalForm(data, user.userId);
  };

  if (approvalGroups) {
    return <CreateApprovalForm onSubmit={onSubmit} user={user} approvalGroups={approvalGroups} />;
  }
  return null;
};

const mapStateToProps = (state) => ({
  user: state.auth,
  approvalGroups: state.approvalForm.approvalGroups,
});

export default connect(mapStateToProps, {
  createApprovalForm,
  getApprovalGroups,
})(NewApprovalFormContainer);
