import { push } from 'connected-react-router';
import { reset } from 'redux-form';
import * as _ from 'lodash';
import { toggleIsDataFetching } from './appReducer';
import { adminAPI } from '../api';
import { hideNote, setNote } from './notificationReducer';
import { serverErrorHelper } from '../utils/helpers/server-error-helper';
import { LOCALE } from '../locale';
import { ADMIN_LIST_APPROVAL_FORM_ROUTE, ADMIN_LIST_APPROVAL_GROUPS_ROUTE } from '../routes';
import { clearEditorValue } from './richtextReducer';

const successMsg = LOCALE.success.approvalGroups;

// Action
const SET_APPROVAL_FORMS_DATA = 'SET_APPROVAL_FORMS_DATA';
const SET_CURRENT_APPROVAL_FORM_DATA = 'SET_CURRENT_APPROVAL_FORM_DATA';
const RESET_CURRENT_APPROVAL_FORMS_DATA = 'RESET_CURRENT_APPROVAL_FORMS_DATA';
const GET_APPROVAL_GROUPS = 'GET_APPROVAL_GROUPS';
const UPDATE_APPROVAL_GROUPS = 'UPDATE_APPROVAL_GROUPS';

// Initial Data
const initialState = {
  approvalFormsList: [],
  approvalGroups: null,
  approvalForm: {
    id: null,
    date: null,
    status: null,
    requestor: null,
    description: null,
    approvalGroup: null,
  },
};

// Reducer
const approvalFormsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_APPROVAL_FORMS_DATA: {
      const { data } = action;
      return {
        ...state,
        approvalFormsList: data || initialState.approvalFormsList,
      };
    }
    case SET_CURRENT_APPROVAL_FORM_DATA: {
      const { data } = action;
      return {
        ...state,
        approvalForm: { ...data },
      };
    }
    case RESET_CURRENT_APPROVAL_FORMS_DATA: {
      return {
        ...state,
        approvalForm: initialState.approvalForm,
      };
    }
    case GET_APPROVAL_GROUPS: {
      const { data } = action;
      return {
        ...state,
        approvalGroups: data || initialState.approvalGroups,
      };
    }
    case UPDATE_APPROVAL_GROUPS: {
      const { data } = action;
      const currentApprovalGroups = _.cloneDeep(state.approvalGroups);
      const result = currentApprovalGroups.filter((item) => item.approvalGroupId !== data.approvalGroupId);
      return {
        ...state,
        approvalGroups: result || initialState.approvalGroups,
      };
    }
    default:
      return state;
  }
};

// Action Creators
export const setApprovalFormsData = (data) => ({
  type: SET_APPROVAL_FORMS_DATA,
  data,
});
export const setCurrentApprovalFormData = (data) => ({
  type: SET_APPROVAL_FORMS_DATA,
  data,
});
export const getApprovalGroupData = (data) => ({
  type: GET_APPROVAL_GROUPS,
  data,
});
export const updateApprovalGroupData = (data) => ({
  type: UPDATE_APPROVAL_GROUPS,
  data,
});
export const resetCurrentApprovalFormData = () => ({
  type: RESET_CURRENT_APPROVAL_FORMS_DATA,
});

// Thunks
export const createApprovalForm = (data, userId) => (dispatch) => {
  dispatch(toggleIsDataFetching(true));
  dispatch(hideNote());
  adminAPI
    .createApprovalForms(data, userId)
    .then(() => {
      dispatch(toggleIsDataFetching(false));
      dispatch(push(ADMIN_LIST_APPROVAL_FORM_ROUTE));
      dispatch(reset('approval-form'));
      dispatch(clearEditorValue());
      dispatch(
        setNote({
          msg: successMsg.forms,
          type: 'success',
          error: false,
          success: true,
        }),
      );
    })
    .catch((error) => serverErrorHelper(dispatch, error));
};

export const createApprovalGroup = (data, userId) => (dispatch) => {
  dispatch(toggleIsDataFetching(true));
  dispatch(hideNote());
  adminAPI
    .createApprovalGroup(data, userId)
    .then(() => {
      dispatch(toggleIsDataFetching(false));
      dispatch(push(ADMIN_LIST_APPROVAL_GROUPS_ROUTE));
      dispatch(reset('approval-group'));
      dispatch(clearEditorValue());
      dispatch(
        setNote({
          msg: successMsg.groups,
          type: 'success',
          error: false,
          success: true,
        }),
      );
    })
    .catch((error) => serverErrorHelper(dispatch, error));
};

export const getApprovalGroups = () => (dispatch) => {
  dispatch(toggleIsDataFetching(true));
  adminAPI
    .getApprovalGroups()
    .then((response) => {
      dispatch(toggleIsDataFetching(false));
      dispatch(getApprovalGroupData(response.data));
    })
    .catch((error) => serverErrorHelper(dispatch, error));
};

export const getAdminApprovalForms = (adminId) => (dispatch) => {
  dispatch(toggleIsDataFetching(true));
  adminAPI
    .getApprovalForms(adminId)
    .then((response) => {
      dispatch(toggleIsDataFetching(false));
      dispatch(setApprovalFormsData(response.data));
    })
    .catch((error) => serverErrorHelper(dispatch, error));
};

export const deleteApprovalGroup = (adminId, groupId) => (dispatch) => {
  dispatch(toggleIsDataFetching(true));
  adminAPI
    .deleteApprovalGroup(adminId, groupId)
    .then((response) => {
      dispatch(toggleIsDataFetching(false));
      dispatch(updateApprovalGroupData(response.data));
    })
    .catch((error) => serverErrorHelper(dispatch, error));
};
export default approvalFormsReducer;
