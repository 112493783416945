import React from 'react';
import { connect } from 'react-redux';
import CreateApprovalGroup from './CreateApprovalGroup';
import { createApprovalGroup } from '../../../../store/approvalFormsReducer';

const NewApprovalGroupContainer = ({ user, createApprovalGroup }) => {
  const onSubmit = (data) => {
    createApprovalGroup(data, user.userId);
  };

  return <CreateApprovalGroup onSubmit={onSubmit} user={user} />;
};

const mapStateToProps = (state) => ({
  user: state.auth,
});

export default connect(mapStateToProps, {
  createApprovalGroup,
})(NewApprovalGroupContainer);
