import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { themeStyles } from './TicketsTable.styles';
import { LOCALE } from '../../../../locale';
import ConfirmModal from '../../../shared/common/ConfirmModal/ConfirmModal';
import { deleteApprovalGroup } from '../../../../store/approvalFormsReducer';

const useStyles = () => createMuiTheme(themeStyles);

const ApprovalGroupsTable = ({ approvalGroups, deleteApprovalGroup, adminId }) => {
  const locale = LOCALE.common.dashboard.approvalGroups;
  const confirmLocale = LOCALE.common.confirmModal.deleteUser;
  const classes = useStyles();
  const [activeGroupId, setActiveGroupId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleShowModal = (id) => {
    setActiveGroupId(id);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setActiveGroupId(null);
    setModalOpen(false);
  };

  const onDelete = () => {
    if (activeGroupId) {
      deleteApprovalGroup(adminId, activeGroupId);
    }
    setModalOpen(false);
    setActiveGroupId(null);
  };

  const columns = [
    {
      name: 'groupName',
      label: locale.tableHeaders.groupName,
      options: {
        filter: false,
        sort: false,
        searchable: true,
      },
    },
    {
      name: 'emails',
      label: locale.tableHeaders.memberEmails,
      options: {
        filter: false,
        sort: false,
        searchable: true,
      },
    },
    {
      name: 'actions',
      label: 'Delete Approval Group',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <IconButton
            aria-label="delete"
            className={classes.removeBtn}
            onClick={() => handleShowModal(value)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        ),
      },
    },
  ];

  const tableData = approvalGroups && _.cloneDeep(approvalGroups)
    .map((item) => {
      item.groupName = item.groupName;
      item.emails = item.emails;
      item.actions = item.approvalGroupId;
      return item;
    });

  const options = {
    download: false,
    print: false,
    selectableRows: false,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 25, 50],
    sortOrder: {
      name: 'createdDate',
      direction: 'desc',
    },
    searchPlaceholder: `${locale.tableHeaders.groupName}`,
    textLabels: {
      body: {
        noMatch: locale.noData,
      },
    },
  };

  return (
    <>
      <MuiThemeProvider theme={useStyles()}>
        <MUIDataTable
          data={tableData || []}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
      <ConfirmModal
        headline={confirmLocale.headline}
        body={confirmLocale.body}
        open={modalOpen}
        onClose={handleCloseModal}
        onSubmit={onDelete}
      />
    </>
  );
};

// export default ApprovalGroupsTable;
const mapStateToProps = (state) => ({
  adminId: state.auth.userId,
});

export default connect(mapStateToProps, { deleteApprovalGroup })(ApprovalGroupsTable);
