import React, { useState } from 'react';
import { reduxForm, Field, change } from 'redux-form';
import { Box, Button, Card, CardContent, Container, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { renderTextField, renderSelectField } from '../../../shared/common/FormControls/FormControls';
import themeStyles from './ProfileForm.styles';
import { LOCALE } from '../../../../locale';
import RichTextarea from '../../../shared/dashboard/RichTextarea/RichTextarea';
import SmallPreloader from '../../../shared/common/SmallPreloader/SmallPreloader';

const useStyles = makeStyles((theme) => themeStyles(theme));

const CreateApprovalForm = ({ dispatch, handleSubmit, approvalGroups }) => {
  const classes = useStyles();
  const [isFetching, setIsFetching] = useState(false); // eslint-disable-line
  const [status, setStatus] = useState(null);
  const locale = LOCALE.common.dashboard.approvalForms;
  const editorHeight = 200;

  const statusChange = (event) => {
    setStatus(event.target.value);
  };
  const onEditorChange = (data) => {
    dispatch(change('approval-form', 'description', data.length ? data : null));
  };
  return (
    <Container maxWidth="md" disableGutters>
      {isFetching ? (
        <SmallPreloader />
      ) : (
        <Card className={classes.root}>
          <CardContent>
            <Box m="0 0 0.5rem" display="flex" justifyContent="center" alignItems="center">
              <Typography variant="h5" component="h1" align="center" gutterBottom>
                {locale.headline}
              </Typography>
            </Box>
            <form onSubmit={handleSubmit}>
              <Field
                name="subject"
                label={locale.subject}
                variant="outlined"
                margin="normal"
                fullWidth
                component={renderTextField}
              />
              <Field
                select
                name="approvalGroup"
                label={locale.approvalGroup}
                variant="outlined"
                size="medium"
                fullWidth
                value={status}
                onChange={statusChange}
                component={renderSelectField}
              >
                {approvalGroups.map((option) => (
                  <MenuItem key={option.groupName} value={option.groupName}>
                    {option.groupName}
                  </MenuItem>
                ))}
              </Field>
              <Box m="1rem 0 0" className="messageBodyEditor">
                <RichTextarea height={editorHeight} onChange={onEditorChange} />
              </Box>
              <Box m="1rem 0 0">
                <Button type="submit" fullWidth variant="contained" color="primary">
                  Save
                </Button>
              </Box>
            </form>
          </CardContent>
        </Card>
      )}
    </Container>
  );
};

export default reduxForm({
  form: 'approval-form',
})(CreateApprovalForm);
