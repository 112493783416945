import React, { useState } from 'react';
import { Field } from 'redux-form';
import _ from 'lodash';
import {
  Box,
  Paper,
  Divider,
  Grid,
  MenuItem,
  InputAdornment,
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { makeStyles } from '@material-ui/core/styles';
import {
  renderSelectField,
  renderTextField,
} from '../../../../shared/common/FormControls/FormControls';
import themeStyles from './NewTicketConfig.styles';
import { LOCALE } from '../../../../../locale';
import { ticketType, ticketPriority, department, departmentCategoryMap } from '../../../../../core';

const useStyles = makeStyles((theme) => themeStyles(theme));

const typeList = Object.entries(ticketType);
const priorityList = Object.entries(ticketPriority);
const departmentList = Object.entries(department);
const locationList = [
  ['MARIE_CURIE', 'Marie Curie'],
  ['FREDERICK_BANTIN', 'Frederick Bantin'],
  ['ARMAND_FRAPPIER', 'Armand – Frappier'],
];

const NewTicketConfig = () => {
  const classes = useStyles();
  const locale = LOCALE.user.dashboard.newTicket.form;
  const [type, setType] = useState(typeList[0][1]);
  const [priority, setPriority] = useState(priorityList[0][1]);
  const [department, setDepartment] = useState(departmentList[0][0]);
  const [category, setCategory] = useState();
  const [location, setLocation] = useState(locationList[0][0]);
  const [categoryList, setCategoryList] = useState([]);
  const [roomNumber, setRoomNumber] = useState(0);

  const priorityChange = (event) => {
    setPriority(event.target.value);
  };
  const typeChange = (event) => {
    setType(event.target.value);
  };
  const departmentChange = (event) => {
    setDepartment(event.target.value);
    if (departmentCategoryMap[event.target.value]) {
      setCategoryList(departmentCategoryMap[event.target.value]);
    } else {
      setCategoryList([]);
    }
  };
  const categoryChange = (event) => {
    setCategory(event.target.value);
  };
  const locationChange = (event) => {
    setLocation(event.target.value);
  };
  const roomNumberChange = _.debounce((event) => {
    setRoomNumber(event.target.value);
  }, 100);

  return (
    <Box className={classes.paperBox}>
      <Paper className={classes.paper}>
        <Field
          label={locale.requester}
          name="requester"
          variant="outlined"
          size="small"
          value="John Doe"
          fullWidth
          className={classes.readonly}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          component={renderTextField}
        />
        <Box m="1rem 0">
          <Field
            select
            required
            name="department"
            label={locale.department}
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={department}
            onChange={departmentChange}
            component={renderSelectField}
          >
            {departmentList.map((option) => (
              <MenuItem key={option[0]} value={option[0]}>
                {option[1]}
              </MenuItem>
            ))}
          </Field>
        </Box>
        <Box m="1rem 0">
          <Field
            select
            required
            name="category"
            label={locale.category}
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={category}
            onChange={categoryChange}
            component={renderSelectField}
          >
            {categoryList.map((option) => (
              <MenuItem key={option[0]} value={option[0]}>
                {option[1]}
              </MenuItem>
            ))}
          </Field>
        </Box>
        <Box m="1rem 0">
          <Field
            select
            name="location"
            label={locale.location}
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            value={location}
            onChange={locationChange}
            component={renderSelectField}
          >
            {locationList.map((option) => (
              <MenuItem key={option[0]} value={option[0]}>
                {option[1]}
              </MenuItem>
            ))}
          </Field>
        </Box>
        <Box m="1rem 0">
          <Field
            label={locale.roomNumber}
            name="roomNumber"
            variant="outlined"
            size="small"
            value={roomNumber}
            fullWidth
            onChange={roomNumberChange}
            InputLabelProps={{
              shrink: true,
            }}
            component={renderTextField}
          />
        </Box>
        <Box m="2rem 0">
          <Divider />
        </Box>
        <Grid container spacing={2}>
          <Box flexGrow={1} p={1}>
            <Field
              select
              name="ticketType"
              label={locale.type}
              variant="outlined"
              size="small"
              fullWidth
              value={type}
              onChange={typeChange}
              component={renderSelectField}
            >
              {typeList.map((option) => (
                <MenuItem key={option[0]} value={option[1]}>
                  {option[1]}
                </MenuItem>
              ))}
            </Field>
          </Box>
          <Box flexGrow={1} p={1}>
            <Field
              select
              name="priority"
              label={locale.priority}
              variant="outlined"
              size="small"
              fullWidth
              value={priority}
              onChange={priorityChange}
              component={renderSelectField}
            >
              {priorityList.map((option) => (
                <MenuItem key={option[0]} value={option[1]}>
                  {option[1]}
                </MenuItem>
              ))}
            </Field>
          </Box>
        </Grid>
      </Paper>
    </Box>
  );
};

export default NewTicketConfig;
